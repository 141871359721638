import { forwardRef } from "react";

import styled from "styled-components/macro";
import { cursor } from "theme";

const SButton = styled("button")`
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  ${cursor};
  font-size: inherit;
  text-align: left;
`;

const FnrButtonWrapper = forwardRef((props, ref) => (
  <SButton ref={ref} role="button" {...props} />
));

export default FnrButtonWrapper;
