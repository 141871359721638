import { bool, node, oneOf } from "prop-types";
import { forwardRef, memo } from "react";

import styled from "styled-components/macro";

import { motion } from "framer-motion";

const Container = memo(({ elementType, children, ...rest }) => {
  let Component;
  switch (elementType) {
    case "button":
      Component = motion.button;
      break;
    default:
      Component = motion.div;
  }
  return <Component {...rest}>{children}</Component>;
});

Container.propTypes = {
  elementType: oneOf(["div", "button"]),
  children: node,
};

const SContainer = styled(Container)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
`;

const FnrTappyShrink = forwardRef(
  /**
   *
   * @param {object} param0
   * @param {"div"|"button"} param0.elementType
   * @param {function} param0.onClick
   * @param {boolean} param0.growOnHover - make it...grow on hover
   * @param {import("react").Ref} ref
   * @returns
   */
  ({ children, elementType = "div", growOnHover, ...rest }, ref) => (
    <SContainer
      elementType={elementType}
      ref={ref}
      whileHover={
        growOnHover
          ? {
              scale: 1.1,
              transition: {
                duration: 0.1,
              },
            }
          : undefined
      }
      whileTap={{
        scale: 0.95,
        transition: {
          duration: 0.1,
        },
      }}
      {...rest}
    >
      {children}
    </SContainer>
  )
);

FnrTappyShrink.propTypes = {
  elementType: oneOf(["div", "button"]),
  growOnHover: bool,
  children: node,
};

export default FnrTappyShrink;
