import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";

import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import useMemoDeep from "Utilities/Hooks/useMemoDeep";

import flocknoteNetworkApi from "./API/fetch";
import { flocknoteKeys } from "./API/keys";
import { useGetNetworkMetadata, useGetUserData } from "./API/queries";
import flocknoteActions from "./actions";
import { useFlocknoteAppState } from "./state";

const defaultObj = {};

/**
 * @typedef {object} NetworkMetadata
 * @property {boolean} success - was the api call successful?
 * @property {boolean} hasFNP - does this network have flocknote people?
 * @property {boolean} hasSignupsProfileFields - Does this network have the ability to add profile fields to signups?
 * @property {boolean} hasUsedFPTrial - has this network ever used the free trial of flocknote people?
 * @property {boolean} hasFundIt - does this network have fundit?
 * @property {boolean} canHaveFundIt - can this network have fundit?
 * @property {boolean} isApp - is this the mobile app?
 * @property {"open"|"closed"} networkType - is it open or closed?
 * @property {boolean} hasAddOnGifts - does the network have the hasAddOnGifts feature flag turned on?
 */

/**
 * returns network metadata and a function that initializes the network's metadata.
 *
 * This is intended to fetch and store things that impact multiple compoments such as which features are active on the network (fundIt, flocknotePeople, etc.).
 * @returns {[NetworkMetadata, function]}
 */
const useNetworkMetadata = () => {
  const { data } = useGetNetworkMetadata();
  return [data || defaultObj];
};

/**
 * returns user's data and a function that initializes the user's data.
 *
 * This is intended to fetch and store things that impact multiple compoments such as which features are available to the user.
 * @returns {[object, function]}
 */
const useUserData = () => {
  const { data } = useGetUserData();
  const queryClient = useQueryClient();

  const patchUserData = useCallback(
    (mergeObject) => {
      const currentData = queryClient.getQueryData(flocknoteKeys.user());
      queryClient.setQueryData(
        flocknoteKeys.user(),
        merge(currentData, mergeObject)
      );
      queryClient.invalidateQueries(flocknoteKeys.user());
    },
    [queryClient]
  );

  return [data || defaultObj, { patchUserData }];
};

// This should be initialized on an as needed basis since it will be rare that we need this information. If this changes, it should be moved for UserInfo.
const useFundItPermissions = () => {
  const [{ fundItPermissions }, dispatch] = useFlocknoteAppState();
  const queryClient = useQueryClient();

  const init = useCallback(() => {
    if (!fundItPermissions) {
      flocknoteNetworkApi
        .getUserFundItPermissions()
        .get()
        .then((res) => {
          dispatch(flocknoteActions.setUserFundItPermissions(res));
          window.fnReactBus.setUserData(res);

          // Adding init data to QueryStore so we dont refetch when queries mount (data will always be assumed fresh for app state) - Utilities/ReactQuery/QueryClient.js
          queryClient.setQueryData(flocknoteKeys.fundItPermissions(), res);
        });
    }
  }, [dispatch, fundItPermissions, queryClient]);

  return [fundItPermissions || defaultObj, init];
};

// This should be initialized on an as needed basis since it will be rare that we need this information. If this changes, it should be moved for UserInfo.
const useStaxStatus = () => {
  const [{ staxStatus }, dispatch] = useFlocknoteAppState();
  const [isLoading, setIsLoading] = useState(false);
  const deepStaxStatus = useMemoDeep(() => staxStatus);
  const queryClient = useQueryClient();

  const patch = useCallback(
    (mergeObject) => {
      const clonedStaxStatus = cloneDeep(deepStaxStatus);
      dispatch(
        flocknoteActions.setStaxStatus(merge(clonedStaxStatus, mergeObject))
      );
    },
    [deepStaxStatus, dispatch]
  );

  const init = useCallback(
    (force) => {
      if (!deepStaxStatus || force) {
        setIsLoading(true);
        flocknoteNetworkApi
          .staxStatus()
          .get()
          .then((res) => {
            dispatch(flocknoteActions.setStaxStatus(res));
            window.fnReactBus.setStaxStatus(res);

            // Adding init data to QueryStore so we dont refetch when queries mount (data will always be assumed fresh for app state) - Utilities/ReactQuery/QueryClient.js
            queryClient.setQueryData(flocknoteKeys.staxStatus(), res);
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [dispatch, deepStaxStatus, queryClient]
  );

  return [
    { ...(staxStatus || defaultObj), isLoading },
    { init, patch },
  ];
};

const useFundItStatus = () => {
  const [{ fundItStatus }, dispatch] = useFlocknoteAppState();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const init = useCallback(() => {
    if (!fundItStatus) {
      setIsLoading(true);
      flocknoteNetworkApi
        .getFundItStatus()
        .get()
        .then((res) => {
          dispatch(flocknoteActions.setFundItStatus(res));
          window.fnReactBus.setFundItStatus(res);

          // Adding init data to QueryStore so we dont refetch when queries mount (data will always be assumed fresh for app state) - Utilities/ReactQuery/QueryClient.js
          queryClient.setQueryData(flocknoteKeys.fundItStatus(), res);
        })
        .catch((err) => {
          // This handling is leftover from a refactor of fnreact/src/Payments/FundItPage/FundItPage.js
          // TODO: replace alerts with something...not bad...then do redirects with react router.
          if (err.error === "no_admin") {
            // only super admins can access this page.
            window.location = "/dashboard";
          } else if (err.error === "invalid_country") {
            alert(
              "We're sorry- only organizations within the U.S. are eligible to sign up for Fund It."
            );
            window.location = "/dashboard";
          } else {
            alert(
              "Oh no! Something went wrong fetching your Fund It account status. Please try refreshing the page, or contact help@flocknote.com"
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch, fundItStatus, queryClient]);

  return [{ value: fundItStatus || defaultObj, isLoading }, init];
};

export {
  useNetworkMetadata,
  useUserData,
  useFundItPermissions,
  useFundItStatus,
  useStaxStatus,
};
