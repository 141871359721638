import PropTypes from "prop-types";
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components/macro";
import { padding } from "theme";

import { AnimatePresence, motion } from "framer-motion";

import { scrollbarInvisibleThin } from "UI/scrollbarStyles";

import useComponentIsMounted from "Utilities/Hooks/useComponentIsMounted";
import Portal from "Utilities/PortalMaker";

import { FnrPopoverContext } from "./Wrapper";

export const FnrPopoverStateContext = createContext();

FnrPopover.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  onShouldClose: PropTypes.func,
  /** Ref of the button that toggles the popover */
  buttonRef: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onAnimationComplete: PropTypes.func,
  style: PropTypes.object,
  offsetLeft: PropTypes.number,
  closeTrigger: PropTypes.number,
  color: PropTypes.oneOf(["blue", "red", "white"]),
  variant: PropTypes.oneOf(["popover", "container"]),
  thinBorder: PropTypes.bool,
  id: PropTypes.string,
  /** an element data tag to ignore */
  dataElementToIgnore: PropTypes.string,
};

export function FnrPopover({
  children,
  show,
  onShouldClose,
  buttonRef,
  onOpen,
  onClose,
  onAnimationComplete,
  style = {},
  offsetLeft = 0,
  closeTrigger = 0,
  color = "blue", // options: "blue", "red", "white"
  variant = "popover",
  thinBorder,
  id,
  dataElementToIgnore,
  ...props
}) {
  // const countRef = useRef(0);
  // countRef.current = countRef.current + 1;
  // console.log({ count: countRef.current });
  const context = useContext(FnrPopoverContext);

  let popoverRef;
  let forceButtonRefForMeasurement;
  let dispatch;

  const [controlled] = useState(!context);

  if (!controlled) {
    show = context[0].show;
    buttonRef = context[0].buttonRef;
    popoverRef = context[0].popoverRef;
    forceButtonRefForMeasurement = context[0].forceButtonRefForMeasurement;
    dispatch = context[1];
  }

  const componentIsMounted = useComponentIsMounted();
  const [position, setPosition] = useState(false);
  const [measured, setMeasured] = useState(false);

  const measuringRef = useRef();
  const controlledPopoverRef = useRef();

  const popoverRefToUse = controlled ? controlledPopoverRef : popoverRef;

  const checkClick = (e) => {
    if (
      show &&
      !popoverRefToUse?.current?.contains(e.target) &&
      !(forceButtonRefForMeasurement?.current || buttonRef.current)?.contains(
        e.target
      )
    )
      attemptClosePopover(e);
  };

  const attemptClosePopover = (e) => {
    const ignoreTarget =
      e && e?.target.getAttribute("data-element") === dataElementToIgnore;

    if (ignoreTarget) return;

    if (!controlled) dispatch({ type: "Hide Popover" });
    else if (onShouldClose) {
      onShouldClose(e);
    }
  };

  useEffect(() => {
    if (componentIsMounted) attemptClosePopover();

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [closeTrigger]);

  useEffect(() => {
    if (!componentIsMounted) return;
    if (!forceButtonRefForMeasurement?.current && !buttonRef?.current) return;

    if (!show) {
      setMeasured(false);
      setPosition(false);
      document.body.removeEventListener("click", checkClick);

      if (componentIsMounted && onClose) onClose();

      return;
    }

    setTimeout(
      () => document.body.addEventListener("pointerdown", checkClick),
      250
    );

    const parentPosition =
      (
        forceButtonRefForMeasurement?.current || buttonRef?.current
      )?.getBoundingClientRect() || {};

    const position = {
      top: (parentPosition.bottom || 0) + 4,
      left: (parentPosition.left || 0) - 4 + offsetLeft,
      originX: 0,
      originY: 0,
    };

    setPosition(position);

    if (onOpen) onOpen();

    return () => document.body.removeEventListener("click", checkClick);

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [show]);

  useLayoutEffect(() => {
    if (show && position && !measured) {
      const measurements = measuringRef?.current?.getBoundingClientRect() || {};

      const newPosition = { ...position };

      if (measurements.right > window.innerWidth - 20) {
        newPosition.left = Math.max(
          20,
          window.innerWidth - measurements.width - 20
        );
      }

      if (newPosition.left + measurements.width > window.innerWidth - 20)
        newPosition.maxWidth = window.innerWidth - 40;

      const parentPosition =
        (
          forceButtonRefForMeasurement?.current || buttonRef?.current
        )?.getBoundingClientRect() || {};

      const parentMiddleX = parentPosition.left + parentPosition.width / 2;
      const popoverWidth = newPosition.maxWidth || measurements.width;
      const overlapX = parentMiddleX - newPosition.left;
      newPosition.originX = overlapX / popoverWidth;

      const parentMiddleY = parentPosition.top + parentPosition.height / 2;

      if (measurements.height > window.innerHeight - 40) {
        newPosition.top = 20;
        newPosition.maxHeight = window.innerHeight - 40;
      } else if (measurements.bottom > window.innerHeight - 20) {
        if (parentPosition.top - 4 - measurements.height > 20) {
          delete newPosition.top;
          newPosition.bottom = window.innerHeight - (parentPosition.top - 4);
        } else {
          let newTop = parentMiddleY - measurements.height / 2;

          const buttonIsInTopHalfOfWindow =
            parentMiddleY < window.innerHeight / 2;

          if (
            !buttonIsInTopHalfOfWindow &&
            newTop + measurements.height > window.innerHeight - 20
          ) {
            newTop = window.innerHeight - 20 - measurements.height;
          } else if (buttonIsInTopHalfOfWindow && newTop < 20) newTop = 20;

          newPosition.top = newTop;
        }
      }

      if (!newPosition.top) newPosition.originY = 1;
      else if (newPosition.top >= parentPosition.bottom)
        newPosition.originY = 0;
      else {
        // const parentMiddleX = parentPosition.left + parentPosition.width / 2;
        const popoverHeight = newPosition.maxHeight || measurements.height;
        const overlapY = parentMiddleY - newPosition.top;
        newPosition.originY = overlapY / popoverHeight;
      }

      setPosition(newPosition);
      setMeasured(true);
    }

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [show, position, measured]);

  const popoverState = {
    show,
    onShouldClose,
  };

  if (!controlled && !onShouldClose)
    popoverState.onShouldClose = () => dispatch({ type: "Hide Popover" });

  if (position) style = { ...style, ...position };

  return (
    <Portal to={document.getElementById("FnrOverlayHolder")}>
      {show && !measured && (
        <Measuring ref={measuringRef} style={style}>
          {children}
        </Measuring>
      )}
      <AnimatePresence>
        {show && measured && (
          <Wrapper
            ref={popoverRefToUse}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{
              scale: 0.8,
              opacity: 0,
              transition: {
                type: "tween",
                duration: 0.3,
                ease: "backIn",
              },
            }}
            onAnimationComplete={onAnimationComplete}
            style={style}
            color={color}
            $variant={variant}
            $thinBorder={thinBorder}
            id={id}
            data-cy="FnrPopover_Container"
          >
            <FnrPopoverStateContext.Provider value={popoverState}>
              {children}
            </FnrPopoverStateContext.Provider>
          </Wrapper>
        )}
      </AnimatePresence>
    </Portal>
  );
}

const Wrapper = styled(motion.div)`
  // TODO some visual, but not ugly :), scroll indicator
  ${scrollbarInvisibleThin};
  overflow: auto;
  position: fixed;
  box-sizing: border-box;
  z-index: 9000;
  min-width: 160px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  color: #fff;
  font-weight: 400;
  ${padding}

  /* FIREFOX (and anywhere backdrop-filter isn't supported) */
  background-image: linear-gradient(to right bottom, #384a5d, #4c6279f2);
  background-image: ${({ color }) =>
    color === "red"
      ? "linear-gradient(to right bottom, rgba(193, 39, 39, 0.9), rgba(136, 3, 3, 0.9));"
      : null};
  background: ${({ color }) =>
    color === "white" ? "rgba(236, 239, 240, 0.9)" : null};

  /* CHROME (and anywhere backdrop-filter is supported) */
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-image: linear-gradient(
      to right bottom,
      rgba(19, 66, 107, 0.6),
      rgba(47, 63, 79, 0.6)
    );
    background-image: ${({ color }) =>
      color === "red"
        ? "linear-gradient( to right bottom, rgba(180, 37, 45, 0.7), rgba(128, 36, 40, 0.7) )"
        : null};
    background: ${({ color }) =>
      color === "white" ? "rgba(236, 239, 240,.5)" : null};
    backdrop-filter: blur(12px) saturate(2);
  }
`;

const Measuring = styled(Wrapper)`
  opacity: 0 !important;
  visibility: hidden !important;
  background: #fff;
  backdrop-filter: none;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
  }
`;

FnrPopover.propTypes = {
  /** Adds additional padding to wrapper */
  addPadding: PropTypes.bool,
};
