import { themeVariants } from "theme/constants";

/**
 * Shades the background of the styled component on hover
 * @param {string} param0.$hoverBackgroundColor - defaults to a medium gray. Set this transient prop of the calling component to any color in theme.js
 * @param {boolean} param0.$noShade - turns off interpolation
 * @param {string} param0.$variant - pass in a themeVariant to get consistant styling
 */
const shadeOnHover = ({
  $hoverBackgroundColor = "fnMediumGrey",
  $noShade,
  $variant,
  theme: { colors },
}) => {
  if ($noShade) return "";
  switch ($variant) {
    case themeVariants.emphasized:
      return `
        transition: background-color 0.3s;
        &:hover {background-color: ${colors.fnLightBlue};}
      `;
    default:
      return `
          transition: background-color 0.3s;
          &:hover {background-color: ${colors[$hoverBackgroundColor]};}
      `;
  }
};

export default shadeOnHover;
