import { themeVariants } from "theme/constants";

const MAX_MODAL_PX_HEIGHT = 500;
const MAX_MODAL_VH = 90;
const MAX_MODAL_HEIGHT = `min(${MAX_MODAL_PX_HEIGHT}px, ${MAX_MODAL_VH}vh)`;

const height = ({
  $maxVh = MAX_MODAL_VH,
  $maxPx = MAX_MODAL_PX_HEIGHT,
  $yAdjust = 0,
  $variant,
  $noHeight,
  $height,
}) => {
  if ($noHeight) return "";
  if ($height) return `height: ${$height}px;`;
  switch ($variant) {
    case themeVariants.contents:
      return `height: calc(min(${$maxPx}px, ${$maxVh}vh) - ${$yAdjust}px);`;
    default:
      return "height: 100%;";
  }
};

export { MAX_MODAL_HEIGHT, MAX_MODAL_VH, MAX_MODAL_PX_HEIGHT };
export default height;
