import { bool, string } from "prop-types";
import { useEffect, useState } from "react";

import { useTheme } from "styled-components";
import styled from "styled-components/macro";

import UnoSolid from "UI/images/UnoSolid";

const InnerDiv = styled("div")`
  @keyframes moveBackAndForth {
    0%,
    100% {
      left: 0;
      transform: rotateY(0deg);
    }
    49% {
      transform: rotateY(0deg);
    }
    50% {
      left: calc(100% - 50px);
      transform: rotateY(180deg);
    }
    99% {
      transform: rotateY(180deg);
    }
  }
  position: absolute;
  animation: moveBackAndForth 1.5s linear infinite; /* Apply animation */
`;

const OuterDiv = styled("div")`
  position: relative;
  height: 54px;
  min-width: ${({ $width }) => ($width ? "100px" : "500px")};
  ${({ $width }) => ($width ? `width: ${$width};` : "")}
`;

/**
 *
 * @param {object} param0
 * @param {color} param0.color
 * @returns
 */
const RunningUno = ({ color, width }) => {
  const { colors, palette } = useTheme();
  const [running, setRunning] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isMounted) {
        setRunning(true);
      }
    }, 2000);
  }, [isMounted]);

  return (
    <OuterDiv $width={width}>
      {running && (
        <InnerDiv>
          <UnoSolid
            color={colors[color] || palette[color] || colors.fnBlue}
            size={48}
          />
        </InnerDiv>
      )}
    </OuterDiv>
  );
};

RunningUno.propTypes = { color: string, width: string };

export default RunningUno;
