const flocknoteActions = {
  SET_GROUP_ID: "SET_GROUP_ID",
  /**
   * Sets the groupId to global context. Used by legacy code to pass the groupId to ExtendedFieldsModal
   * @param {number} groupId
   */
  setGroupId: (groupId) => ({
    type: flocknoteActions.SET_GROUP_ID,
    groupId,
  }),
  SET_SHOW_PEOPLE_SPLASH: "SET_SHOW_PEOPLE_SPLASH",
  /**
   * Sets whether or not to show the flocknote people advertisement.
   *
   * Note that this is not for a modal launching, but rather a variable used by other components to determine which UI to show.
   * @param {boolean} show
   */
  setShowPeopleSplash: (showPeopleSplash) => ({
    type: flocknoteActions.SET_SHOW_PEOPLE_SPLASH,
    showPeopleSplash,
  }),
  SET_HAS_USED_FP_TRIAL: "SET_HAS_USED_FP_TRIAL",
  /**
   * Sets whether or not the networks has already used their Flocknote People free trial.
   */
  setHasUsedFPTrial: (hasUsedFPTrial) => ({
    type: flocknoteActions.SET_HAS_USED_FP_TRIAL,
    hasUsedFPTrial,
  }),
  SET_NETWORK_TYPE: "SET_NETWORK_TYPE",
  setNetworkType: (networkType) => ({
    type: flocknoteActions.SET_NETWORK_TYPE,
    networkType,
  }),
  SET_METADATA: "SET_METADATA",
  /**
   * Sets all data recieved from `network/metadata` to thier respective slices of state.
   * @param {object} param0
   * @param {boolean} param0.hasUsedFPTrial
   * @param {boolean} param0.showPeopleSplash
   */
  setMetadata: (networkMetadata) => ({
    type: flocknoteActions.SET_METADATA,
    networkMetadata,
  }),
  SET_USERDATA: "SET_USERDATA",
  setUserData: (userData) => ({
    type: flocknoteActions.SET_USERDATA,
    userData,
  }),
  SET_USER_FUNDIT_PERMISSIONS: "SET_USER_FUNDIT_PERMISSIONS",
  setUserFundItPermissions: (fundItPermissions) => ({
    type: flocknoteActions.SET_USER_FUNDIT_PERMISSIONS,
    fundItPermissions,
  }),
  SET_FUNDIT_STATUS: "SET_FUNDIT_STATUS",
  setFundItStatus: (fundItStatus) => ({
    type: flocknoteActions.SET_FUNDIT_STATUS,
    fundItStatus,
  }),
  SET_STAX_STATUS: "SET_STAX_STATUS",
  setStaxStatus: (staxStatus) => ({
    type: flocknoteActions.SET_STAX_STATUS,
    staxStatus,
  }),
  CLOSE_MODAL: "CLOSE_MODAL",
  closeModal: () => ({
    type: flocknoteActions.CLOSE_MODAL,
  }),
  OPEN_MODAL: "OPEN_MODAL",
  openModal: (contentKey, contentProps) => ({
    type: flocknoteActions.OPEN_MODAL,
    contentKey,
    contentProps,
  }),
  SET_HAS_ADD_ON_GIFTS: "SET_HAS_ADD_ON_GIFTS",
  setHasAddOnGifts: (hasAddOnGifts) => ({
    type: flocknoteActions.SET_HAS_ADD_ON_GIFTS,
    hasAddOnGifts,
  }),
};

export default flocknoteActions;
