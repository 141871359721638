const themeSizes = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  decorative1: "decorative1",
  decorative2: "decorative2",
  decorative3: "decorative3",
};

const themeVariants = {
  modal: "modal",
  badge: "badge",
  accordion: "accordion",
  input: "input",
  container: "container",
  outerContainer: "outerContainer",
  popover: "popover",
  selectList: "selectList",
  button: "button",
  info: "info",
  disabled: "disabled",
  shown: "shown",
  emphasized: "emphasized",
  draggable: "draggable",
  hidden: "hidden",
  icon: "icon",
  square: "square",
  contents: "contents",
  premium: "premium",
};

const colorVariants = {
  dark: "dark",
  light: "light",
  default: "default",
  popIt: "popIt",
  error: "error",
  completed: "completed",
};

const positionVariants = {
  upperRight: "upperRight",
  lowerRight: "lowerRight",
  upperLeft: "upperLeft",
  lowerLeft: "lowerLeft",
};

const shadowVariants = {
  insetDark: "insetDark",
};

const colorKeys = {
  /** The primary flocknote color. Often overridden by the legacy shade of blue. Use brandFnBlue for now. */
  fnBlue: "fnBlue",
  /** Alias for fnBlue. */
  brandFnBlue: "brandFnBlue",
  blue: "blue",
  darkBlue: "darkBlue",
  lightBlue: "lightBlue",
  red: "red",
  green: "green",
  yellow: "yellow",
  grey: "grey",
  gray: "gray",
  sky: "sky",
  maize: "maize",
  celadon: "celadon",
  apricot: "apricot",
  taupe: "taupe",
  completed: "completed",
};

const colors = {
  // primary colors

  // blues
  [colorKeys.fnBlue]: "#2867ac",
  [colorKeys.brandFnBlue]: "#2867ac", // fnBlue is used in the legacy colors, so we need this too.
  [colorKeys.blue]: "#4e99d1",
  [colorKeys.darkBlue]: "#182941",
  [colorKeys.lightBlue]: "#c7e2f2",

  //misc
  [colorKeys.red]: "#d9705e",
  [colorKeys.green]: "#c8d3ac",
  [colorKeys.yellow]: "#ecc27b",
  [colorKeys.grey]: "#f2f2f1",
  [colorKeys.gray]: "#f2f2f1",
  [colorKeys.completed]: "#84c760",

  // secondary colors
  [colorKeys.sky]: "#90c4e8",
  [colorKeys.maize]: "#fbdc91",
  [colorKeys.celadon]: "#adb796",
  [colorKeys.apricot]: "#e09f8a",
  [colorKeys.taupe]: "#6f6c6a",
};

const virtues = {
  veracity: "veracity",
  hope: "hope",
  wisdom: "wisdom",
  justice: "justice",
  humility: "humility",
  courage: "courage",
  temperance: "temperance",
  patience: "patience",
  love: "love",
  faith: "faith",
};

const widthBySize = {
  [themeSizes.xs]: 20,
  [themeSizes.sm]: 24,
  [themeSizes.md]: 28,
  [themeSizes.lg]: 32,
  [themeSizes.xl]: 40,
  [themeSizes.decorative1]: 44,
  [themeSizes.decorative2]: 76,
  [themeSizes.decorative3]: 112,
};

const SMALL_POPOVER_WIDTH = 300;
const TINY_POPOVER_WIDTH = 132;

export {
  themeSizes,
  themeVariants,
  positionVariants,
  colorVariants,
  shadowVariants,
  SMALL_POPOVER_WIDTH,
  TINY_POPOVER_WIDTH,
  colors,
  virtues,
  colorKeys,
  widthBySize,
};
