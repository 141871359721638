export const EXTENDED_FIELDS = "ExtendedFields";
export const CREATE_ADDON_GIFT = "CreateAddOnGift";
export const CTA_EDITOR = "CtaEditor";
export const ALERT_EDITOR = "AlertEditor";
export const STAX_KYC = "StaxKYC";
export const STAX_STATUS = "StaxStatus";
export const GIFT_REFUND = "GiftRefund";
export const FNC_UPGRADE = "FNCUpgrade";
export const SACRAMENT_TRACKING = "SacramentTracking";
export const TWO_FA_ENROLLMENT = "twofaEnrollment";
export const TWO_FA_DISENROLLMENT = "twofaDisenrollment";
