import { any, bool, func, number, object, oneOf, string } from "prop-types";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components/macro";

import { AnimatePresence, motion } from "framer-motion";

import useComponentIsMounted from "Utilities/Hooks/useComponentIsMounted";
import Portal from "Utilities/PortalMaker";

import { FnrPopoverContext } from "./Wrapper";

export const FnrPopoverStateContext = createContext();

FnrPopoverWithScroll.propTypes = {
  children: any,
  show: bool,
  onShouldClose: func,
  buttonRef: any,
  onOpen: func,
  onClose: func,
  onAnimationComplete: func,
  style: object,
  offsetTop: number,
  offsetLeft: number,
  closeTrigger: number,
  color: oneOf(["blue", "red", "white", "gray"]), // options: "blue", "red", "white", "gray"
  bgTransparency: number, // 0-1
  scrollingElClassname: string,
  location: oneOf(["top", "bottom"]),
};

export function FnrPopoverWithScroll({
  children,
  show,
  onShouldClose = () => {},
  buttonRef,
  onOpen,
  onClose,
  onAnimationComplete,
  style = {},
  offsetTop = 0,
  offsetLeft = 0,
  closeTrigger = 0,
  color = "blue", // options: "blue", "red", "white"
  bgTransparency = 0.9, // 0-1
  scrollingElClassname, // classname of the element that scrolls when the popover is open - must have the . in front
  location = "top", // options: "top", "bottom"
  ...props
}) {
  const context = useContext(FnrPopoverContext);

  let popoverRef;
  let forceButtonRefForMeasurement;
  let dispatch;

  const [controlled] = useState(!context);

  if (!controlled) {
    show = context[0].show;
    buttonRef = context[0].buttonRef;
    popoverRef = context[0].popoverRef;
    forceButtonRefForMeasurement = context[0].forceButtonRefForMeasurement;
    dispatch = context[1];
  }

  const componentIsMounted = useComponentIsMounted();
  const [position, setPosition] = useState(false);
  const [measured, setMeasured] = useState(false);

  const measuringRef = useRef();
  const controlledPopoverRef = useRef();

  const popoverRefToUse = controlled ? controlledPopoverRef : popoverRef;

  const checkClick = (e) => {
    if (
      show &&
      !popoverRefToUse?.current?.contains(e.target) &&
      !(forceButtonRefForMeasurement?.current || buttonRef.current)?.contains(
        e.target
      )
    )
      attemptClosePopover(e);
  };

  const attemptClosePopover = (e) => {
    if (!controlled) dispatch({ type: "Hide Popover" });
    else if (onShouldClose) onShouldClose(e);
  };

  const measureAndSetPosition = useCallback(
    (currentRef) => {
      // the size and position of the content of the popover
      const measurements = measuringRef?.current?.getBoundingClientRect();
      if (!measurements) return;

      const newPosition = { ...position };

      // force content onto screen if it would overflow the right of the screen
      if (measurements.right > window.innerWidth - 20) {
        newPosition.left = Math.max(
          20,
          window.innerWidth - measurements.width - 20
        );
      }

      if (newPosition.left + measurements.width > window.innerWidth - 20)
        newPosition.maxWidth = window.innerWidth - 40;

      // the parent is the button or whatever that you click on to trigger the popover
      const parentPosition = (
        forceButtonRefForMeasurement?.current || currentRef
      ).getBoundingClientRect();

      // Figures out where to place the popover horizontally on the screen
      const parentMiddleX = parentPosition.left + parentPosition.width / 2; // where horizontally on the screen is the center of the parent
      const popoverWidth = newPosition.maxWidth || measurements.width;
      const overlapX = parentMiddleX - newPosition.left; //
      newPosition.originX = overlapX / popoverWidth;

      // Figures out where to place the popover vertically on the screen
      const parentMiddleY = parentPosition.top + parentPosition.height / 2; // where vertically on the screen is the center of the parent

      delete newPosition.top;
      if (location === "top") {
        // put the popover above the parent button
        newPosition.bottom = window.innerHeight - (parentPosition.top - 4);
        newPosition.originY = 1;
      } else {
        // essentially position === "bottom"
        // put the popover below the parent button
        newPosition.top = parentPosition.top + parentPosition.height + 4;
      }

      if (!newPosition.top);
      else if (newPosition.top >= parentPosition.bottom)
        newPosition.originY = 0;
      else {
        const popoverHeight = newPosition.maxHeight || measurements.height;
        const overlapY = parentMiddleY - newPosition.top;
        newPosition.originY = overlapY / popoverHeight;
      }

      setPosition(newPosition);
      setMeasured(true);
    },
    [forceButtonRefForMeasurement, position, location]
  );

  useEffect(() => {
    if (componentIsMounted) attemptClosePopover();

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [closeTrigger]);

  useEffect(() => {
    if (!show) {
      setMeasured(false);
      setPosition(false);
      document.body.removeEventListener("click", checkClick);

      if (componentIsMounted && onClose) onClose();

      return;
    }

    setTimeout(() => document.body.addEventListener("click", checkClick), 250);

    const parentPosition = (
      forceButtonRefForMeasurement?.current || buttonRef.current
    ).getBoundingClientRect();

    const position = {
      top: parentPosition.bottom + 4 + offsetTop,
      left: parentPosition.left - 4 + offsetLeft,
      originX: 0,
      originY: 0,
    };

    setPosition(position);

    if (onOpen) onOpen();

    return () => document.body.removeEventListener("click", checkClick);

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [show]);

  useLayoutEffect(() => {
    if (show && position && !measured) {
      measureAndSetPosition(buttonRef.current);
    }

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [show, position, measured]);

  const handleScroll = useCallback(
    function (e) {
      console.log("hit");
      measureAndSetPosition(buttonRef.current);
    },
    [buttonRef, measureAndSetPosition]
  );

  const handleResize = useCallback(
    function () {
      // We're closing the popover when someone resizes the window
      // because things got really wonky when I tried to reposition on resize -
      // like there were hundreds of erroneous rerenders
      onShouldClose();
    },
    [onShouldClose]
  );

  useEffect(() => {
    if (!show) return;

    let el;
    if (!scrollingElClassname) {
      el = document;
    } else {
      el = document.querySelector(scrollingElClassname);
    }

    if (!el) return;
    el.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      el.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
    };
  }, [
    buttonRef,
    show,
    measureAndSetPosition,
    handleResize,
    handleScroll,
    scrollingElClassname,
  ]);

  const popoverState = {
    show,
    onShouldClose,
  };

  if (!controlled && !onShouldClose)
    popoverState.onShouldClose = () => dispatch({ type: "Hide Popover" });

  if (position) style = { ...style, ...position };

  return (
    <Portal to={document.getElementById("FnrOverlayHolder")}>
      {show && (
        <Measuring ref={measuringRef} style={style}>
          {children}
        </Measuring>
      )}
      <AnimatePresence>
        {show && measured && (
          <Wrapper
            ref={popoverRefToUse}
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{
              scale: 0.5,
              opacity: 0,
              transition: {
                type: "tween",
                duration: 0.4,
                ease: "backIn",
              },
            }}
            onAnimationComplete={onAnimationComplete}
            style={style}
            color={color}
            $bgTransparency={bgTransparency}
          >
            <FnrPopoverStateContext.Provider value={popoverState}>
              {children}
            </FnrPopoverStateContext.Provider>
          </Wrapper>
        )}
      </AnimatePresence>
    </Portal>
  );
}

const Wrapper = styled(motion.div)`
  position: fixed;
  box-sizing: border-box;
  z-index: 9000;
  min-width: 160px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  color: #fff;
  font-weight: 400;
  padding: 4px;

  /* FIREFOX (and anywhere backdrop-filter isn't supported) */
  background-image: linear-gradient(to right bottom, #384a5d, #4c6279f2);
  background-image: ${({ color, $bgTransparency }) =>
    color === "red"
      ? `linear-gradient(to right bottom, rgba(193, 39, 39, ${$bgTransparency}), rgba(136, 3, 3, ${$bgTransparency}));`
      : color === "gray"
      ? `linear-gradient(to right bottom, rgba(236, 239, 240, ${$bgTransparency}), 	rgba(236, 239, 240, ${$bgTransparency}));`
      : null};
  background: ${({ color, $bgTransparency }) =>
    color === "white" ? `rgba(236, 239, 240, ${$bgTransparency});` : null};

  /* CHROME (and anywhere backdrop-filter is supported) */
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-image: linear-gradient(
      to right bottom,
      rgba(19, 66, 107, 0.6),
      rgba(47, 63, 79, 0.6)
    );
    background-image: ${({ color, $bgTransparency }) =>
      color === "red"
        ? `linear-gradient(to right bottom, rgba(193, 39, 39, ${$bgTransparency}), rgba(136, 3, 3, ${$bgTransparency}));`
        : color === "gray"
        ? `linear-gradient(to right bottom, rgba(236, 239, 240, ${$bgTransparency}), 	rgba(236, 239, 240, ${$bgTransparency}));`
        : null};
    background: ${({ color, $bgTransparency }) =>
      color === "white" ? `rgba(236, 239, 240,${$bgTransparency})` : null};
    backdrop-filter: blur(12px) saturate(2);
  }
`;

const Measuring = styled(Wrapper)`
  opacity: 0 !important;
  visibility: hidden !important;
  background: #fff;
  backdrop-filter: none;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
  }
`;
