import {
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from "prop-types";
import React, { forwardRef, useRef } from "react";

import styled from "styled-components/macro";
import {
  backgroundColor,
  color,
  cursor,
  hoverFocus,
  opacity,
  themeSizes,
} from "theme";

import FnrIcon from "UI/FnrIcon/FnrIcon";
import FnrLoadingBricks from "UI/FnrLoadingBricks";
import FnrSlides from "UI/FnrSlides";
import FnrTappyShrink from "UI/FnrTappyShrink/FnrTappyShrink";
import FnrTooltip from "UI/FnrTooltip2/FnrTooltip";

import variants from "./fnrIconButtonVariants";

const hoverPropsByVariant = {
  [variants.default]: { color: "textLight", backgroundColor: "fnBlue" },
  [variants.error]: { color: "textLight", backgroundColor: "error" },
  [variants.danger]: { color: "error", backgroundColor: "transparent" },
  [variants.dark]: { color: "fnBlue", backgroundColor: "fnGrey" },
  [variants.draggable]: { color: "divider", backgroundColor: "transparent" },
  [variants.warning]: { color: "textSecondary", backgroundColor: "fnGrey" },
  [variants.secondary]: { color: "textLight", backgroundColor: "brandFnBlue" },
  [variants.inverted]: {
    color: "textPrimary",
    backgroundColor: "white",
  },
};

const propsByVariant = {
  [variants.default]: { color: "textPrimary", backgroundColor: "white" },
  [variants.error]: { color: "textPrimary", backgroundColor: "error" },
  [variants.danger]: { color: "textPrimary", backgroundColor: "transparent" },
  [variants.dark]: { color: "textLight", backgroundColor: "transparent" },
  [variants.draggable]: { color: "divider", backgroundColor: "transparent" },
  [variants.warning]: { color: "textWarning", backgroundColor: "transparent" },
  [variants.secondary]: {
    color: "grey",
    backgroundColor: "taupe",
    opacity: 0.3,
  },
  [variants.inverted]: {
    color: "textPrimary",
    backgroundColor: "white",
    opacity: 0.4,
  },
};

const sizeStyle = ({ $size }) => {
  switch ($size) {
    case themeSizes.sm:
      return `font-size: 14px;`;
    default:
      return `font-size: 20px;`;
  }
};

const IconButtonContainer = styled("button")`
  padding: 0 0 0 0.5px; /* Not totally sure when this is needed, but it looks off otherwise */
  border: 0;
  border-radius: 50%;
  ${sizeStyle}
  height: 28px;
  width: 28px;
  ${cursor};
  ${color}
  ${backgroundColor}
  ${hoverFocus}
  ${opacity}
  ${({ $backgroundBlur }) => `
    ${$backgroundBlur ? `backdrop-filter: blur(4px);` : ""}
  `}
  ${({ $xAdjust }) =>
    $xAdjust ? `& > div {transform: translate(${$xAdjust}px);}` : ""}
  transition: color 300ms, background-color 300ms, opacity 300ms;
`;

const ButtonTextRelay = ({ tooltipText, children, hoverFocusRef }) => {
  if (tooltipText)
    return (
      <FnrTooltip
        data-cy="IconButton_Tooltip"
        content={tooltipText}
        hoverFocusRef={hoverFocusRef}
      >
        {children}
      </FnrTooltip>
    );
  return children;
};

ButtonTextRelay.propTypes = {
  tooltipText: oneOfType([string, func, node]),
  children: node,
  hoverFocusRef: object,
};

const componentsConfig = [
  {
    key: "icon",
    value: ({
      xAdjust,
      componentRef,
      onClick,
      ariaLabel,
      size,
      componentVariant,
      backgroundBlur,
      backgroundColor: backgroundColorArg,
      color: colorArg,
      hoverFocusColor,
      hoverFocusBackgroundColor,
      opacity: opacityArg,
      icon,
      ...rest
    }) => (
      <IconButtonContainer
        $xAdjust={xAdjust}
        ref={componentRef}
        onClick={onClick}
        aria-label={ariaLabel}
        $variant={componentVariant}
        $size={size}
        type="button"
        $backgroundBlur={backgroundBlur}
        $backgroundColor={backgroundColorArg}
        $color={colorArg}
        $hoverFocusColor={hoverFocusColor}
        $hoverFocusBackgroundColor={hoverFocusBackgroundColor}
        $opacity={opacityArg}
        {...rest}
      >
        <FnrIcon icon={icon} />
      </IconButtonContainer>
    ),
  },
  {
    key: "loading",
    value: () => <FnrLoadingBricks size={28} show />,
  },
];

const FnrIconButton = forwardRef(
  /**
   * @component
   * @param {object} param0
   * @param {function} param0.onClick - forwarded to IconButtonContainer
   * @param {string} param0.icon - fowarded to FnrIcon
   * @param {string} param0.tooltipText - enables tooltip funcitonality
   * @param {"default"|"danger"|"dark"|"error"|"warning"|"secondary"|"inverted"} param0.variant
   * @param {"sm"|"md"} param0.size - changes the size of the icon (but not the clickable area)
   * @param {boolean} param0.backgroundBlur - Makes stuff behind the icon blurry. It's awesome.
   * @param {color} param0.color - overrides variant and sets the color.
   * @param {color} param0.hoverFocusColor - overrides variant and sets the hoverFocusColor
   * @param {color} param0.hoverFocusBackgroundColor - overrides variant and sets the hoverFocusBackgroundColor.
   * @param {number} param0.xAdjust - use this to tweak the position of the icon inside the hoverable area.
   * @param {boolean} param0.isLoading - adds a loading state
   * @param {boolean} param0.growOnHover - passed down to FnrTappyShrink
   * @param {React.Ref} ref
   */
  (
    {
      onClick,
      icon,
      "aria-label": ariaLabel,
      tooltipText,
      variant = "default",
      size,
      backgroundBlur,
      color: colorProp,
      hoverFocusColor,
      hoverFocusBackgroundColor,
      xAdjust,
      isLoading,
      growOnHover,
      ...rest
    },
    refArg
  ) => {
    const localRef = useRef();
    const ref = refArg || localRef;
    const {
      color: $color,
      backgroundColor: backgroundColorArg,
      opacity: opacityArg = 1,
    } = propsByVariant[variant];
    const {
      color: $hoverFocusColor,
      backgroundColor: $hoverFocusBackgroundColor,
    } = hoverPropsByVariant[variant];
    return (
      <ButtonTextRelay tooltipText={tooltipText} hoverFocusRef={ref}>
        <FnrTappyShrink growOnHover={growOnHover}>
          <FnrSlides
            activeKey={isLoading ? "loading" : "icon"}
            variant="subtle"
            components={componentsConfig}
            componentRef={ref}
            componentVariant={variant}
            color={colorProp || $color}
            backgroundColor={backgroundColorArg}
            opacity={opacityArg}
            hoverFocusColor={hoverFocusColor || $hoverFocusColor}
            hoverFocusBackgroundColor={
              hoverFocusBackgroundColor || $hoverFocusBackgroundColor
            }
            onClick={onClick}
            ariaLabel={ariaLabel}
            size={size}
            backgroundBlur={backgroundBlur}
            icon={icon}
            xAdjust={xAdjust}
            {...rest}
          />
        </FnrTappyShrink>
      </ButtonTextRelay>
    );
  }
);

FnrIconButton.propTypes = {
  onClick: func.isRequired,
  icon: string.isRequired,
  "aria-label": string.isRequired,
  /** pass this in to enable tooltip functionality */
  tooltipText: string,
  variant: oneOf(Object.values(variants)),
  size: oneOf(["md", "sm"]),
  backgroundBlur: bool,
  color: string,
  hoverFocusColor: string,
  hoverFocusBackgroundColor: string,
  xAdjust: number,
  isLoading: bool,
  growOnHover: bool,
};

export default FnrIconButton;
