import {
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { createContext, useRef } from "react";

import useScrollTo from "Utilities/Hooks/useScrollTo";

import MotionContainer from "./MotionContainer";

const FnrSlidesContext = createContext();

const FnrSlides = ({
  components,
  activeKey = 0,
  fullWidth,
  hideHeightMotion,
  clip,
  variant = "standard",
  axis = "x",
  ...rest
}) => {
  const ref = useRef();

  // if in a scrollable container, go to the top of it when changing slides
  useScrollTo({
    scrollElementRef: ref,
    scrollTrigger: activeKey,
    actOnParent: true,
  });

  return (
    <FnrSlidesContext.Provider
      value={{
        components,
        activeKey,
        fullWidth,
        variant,
        componentProps: rest,
        hideHeightMotion,
        axis,
      }}
    >
      <MotionContainer ref={ref} clip={clip} />
    </FnrSlidesContext.Provider>
  );
};

const fnrSlidesVariants = { standard: "standard", subtle: "subtle" };

FnrSlides.propTypes = {
  components: arrayOf(shape({ key: string.isRequired, value: func.isRequired }))
    .isRequired,
  activeKey: oneOfType([string, number]),
  fullWidth: bool,
  clip: bool,
  variant: oneOf(["standard", "subtle"]),
  hideHeightMotion: bool,
  axis: oneOf(["x", "y"]),
};

export { FnrSlidesContext, fnrSlidesVariants };
export default FnrSlides;
