import { themeSizes, themeVariants } from "theme/constants";

const CONTAINER_PADDING = 12;
const OUTER_CONTAINER_PADDING = 20;
const PREMIUM_PADDING = 40;

/**
 * Gives element padding based on $variant.
 * @param {object} param0
 * @param {string} param0.$variant
 * @param {boolean} param0.$contentEmphasized - indicates that the contents have more x padding the should be compensated for.
 */
const padding = ({
  $variant,
  $thinBorder,
  $contentEmphasized,
  $size,
  $noPadding,
}) => {
  if ($noPadding) return "padding: 0px 0px;";
  if ($variant === themeVariants.icon) {
    switch ($size) {
      case themeSizes.decorative1:
        return `padding: 12px 0px;`;
      case themeSizes.decorative2:
        return `padding: 22px 0px;`;
      case themeSizes.decorative3:
        return `padding: 32px 0px;`;
      case themeSizes.xs:
      case themeSizes.sm:
      case themeSizes.md:
      case themeSizes.lg:
      case themeSizes.xl:
      default:
        return ""; // these should use the negitiveMargin interpolation. Padding is for decoritive icons only.
    }
  }
  switch ($variant) {
    case "modal":
    case "hidden":
      return `
          padding: 0;
        `;
    case "badge":
      return `
          padding: 0 8px;
        `;
    case "input":
      return `
          padding: 0px 16px;
          height: 48.5px; /* using height as padding to allow y "padding" to adjust and give us a total height of 50.5px */
        `;
    case "container":
      if ($contentEmphasized)
        return `
          padding: 8px ${CONTAINER_PADDING}px;
        `;
      return `
          padding: ${CONTAINER_PADDING}px;
        `;
    case "outerContainer":
      return `
          padding: ${OUTER_CONTAINER_PADDING}px;
        `;
    case "premium":
      return `
        padding: ${PREMIUM_PADDING}px;
      `;
    case "popover":
      if ($thinBorder) {
        return `
          padding: 2px;
        `;
      }
      return `padding: 4px;`;
    default:
      return `
          padding: 8px;
        `;
  }
};

export { CONTAINER_PADDING, OUTER_CONTAINER_PADDING, PREMIUM_PADDING };
export default padding;
