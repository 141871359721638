import { bool, number, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import styled, { useTheme } from "styled-components/macro";

import { motion } from "framer-motion";

export default function LoadingBricks({
  loading = false,
  color: colorArg = "#FFFFFF",
  children,
  remeasure = 0,
  size = false,
  alignTop,
}) {
  const { colors, palette } = useTheme();
  const color = colors[colorArg] || palette[colorArg] || colorArg;
  const [shouldShow, setShouldShow] = useState(loading);
  const [shouldHide, setShouldHide] = useState(false);

  const [width, setWidth] = useState(size);
  const wrapperRef = useRef();

  const measureContainer = () => {
    if (size) return;

    const wrapperMeasurements = wrapperRef.current?.getBoundingClientRect();

    setWidth(
      Math.min(
        wrapperMeasurements.width * 0.8,
        wrapperMeasurements.height * 0.8,
        wrapperMeasurements.width - 12,
        wrapperMeasurements.height - 12
      )
    );
  };

  useEffect(() => {
    if (loading) {
      setShouldShow(true);
      setShouldHide(false);
      setNumberOfBoxesHidden(0);
    } else if (!loading && shouldShow) setShouldHide(true);
  }, [loading, shouldShow]);

  useEffect(() => {
    measureContainer();
  }, [remeasure, loading]);

  useEffect(() => {
    if (size) setWidth(size);
  }, [size]);

  const [numberOfBoxesHidden, setNumberOfBoxesHidden] = useState(0);

  const boxIsHidden = () => {
    if (shouldHide) setNumberOfBoxesHidden((current) => current + 1);
  };

  useEffect(() => {
    if (numberOfBoxesHidden === 3) setShouldShow(false);
  }, [numberOfBoxesHidden]);

  const fleaWrapperStyle = size
    ? {
        height: size,
        width: size,
        top: "50%",
        left: "50%",
        marginLeft: 0 - size / 2,
        marginTop: 0 - size / 2,
      }
    : {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      };

  return (
    <Container
      $alignTop={alignTop}
      ref={wrapperRef}
      data-cy="FnrLoadingBricks_Container"
    >
      {shouldShow && (
        <FleaWrapper
          style={fleaWrapperStyle}
          $alignTop={alignTop}
          data-cy="LoadingBricks"
        >
          <FleaBox
            style={{ width: width }}
            $color={color}
            data-cy="FnrLoadingBricks_FleaBox"
          >
            <Box boxID={1} hide={shouldHide} onBoxHide={boxIsHidden} />
            <Box boxID={2} hide={shouldHide} onBoxHide={boxIsHidden} />
            <Box boxID={3} hide={shouldHide} onBoxHide={boxIsHidden} />
          </FleaBox>
        </FleaWrapper>
      )}
      <ChildrenContainer
        data-cy="FnrLoadingBricks_ChildrenContainer"
        animate={
          loading
            ? {
                scale: 0.5,
                opacity: 0,
                transition: { type: "tween", duration: 0.2 },
              }
            : { scale: 1, opacity: 1 }
        }
      >
        {children}
      </ChildrenContainer>
    </Container>
  );
}

LoadingBricks.propTypes = {
  loading: bool,
  color: string,
  remeasure: number,
  size: number,
};

function Box({ boxID, hide = false, onBoxHide }) {
  const positions = [
    { x: 0, y: 0 },
    { x: "100%", y: 0 },
    { x: "100%", y: "100%" },
    { x: 0, y: "100%" },
  ];

  const [positionNumber, setPositionNumber] = useState(
    boxID === 1 ? 2 : boxID === 2 ? 1 : 0
  );

  const nextPosition = () => {
    if (hide) return onBoxHide();
    setPositionNumber((currentVal) => {
      if (currentVal === positions.length - 1) return 0;
      return currentVal + 1;
    });
  };

  useEffect(() => {
    if (hide) {
      setPositionNumber(boxID === 1 ? 2 : boxID === 2 ? 1 : 0);
      return;
    }

    const animationTimeout = setTimeout(() => {
      nextPosition(false);
    }, boxID * 175 - 175);

    return () => clearTimeout(animationTimeout);
  }, [hide]);

  const opacity = [1, 1, 0.82, 0.64];

  return (
    <BoxWrapper
      initial={{ x: "50%", y: "50%", scale: 0.5, opacity: 0 }}
      animate={
        !hide
          ? { ...positions[positionNumber], scale: 1, opacity: opacity[boxID] }
          : {
              x: "50%",
              y: "50%",
              scale: 0.5,
              opacity: 0,
              transition: { type: "tween", duration: 0.2 },
            }
      }
      onAnimationComplete={nextPosition}
    />
  );
}

const Container = styled.div`
  position: relative;
  height: ${({ $alignTop }) => ($alignTop ? "inherit" : "auto")};
`;

const FleaWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FleaBox = styled(motion.div)`
  --boxColor: ${({ $color, theme: { colors } }) => colors[$color] || $color};
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

const BoxWrapper = styled(motion.div)`
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    display: block;
    background: var(--boxColor);
    width: 80%;
    height: 80%;
    border-radius: 36%;
  }
`;
const ChildrenContainer = styled(motion.div)``;
