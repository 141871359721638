const { themeVariants } = require("theme/constants");

/**
 * Gives element a border radius based on $variant.
 * @param {object} param0
 * @param {string} param0.$variant
 * @param {number} param0.$paddingOffset
 * @param {boolean} param0.$paddingOffset - use this to adjust the border-radius due to the outer container's padding.
 */
const borderRadius = ({ $variant, $paddingOffset = 0, $squareBottom }) => {
  switch ($variant) {
    case themeVariants.square:
      return `border-radius: 0px;`;
    case "icon":
      return `
      border-radius: 50%;
    `;
    case "modal":
      return `
          border-radius: 20px;
        `;
    case "badge":
    case "input":
      return `
          border-radius: 10px;
        `;
    case themeVariants.emphasized: {
      // the emphasized variant results in things looking like there is only 4px of padding inside a container that has 8px border-radius. Thus, 4px.
      const mainValue = `${4 - $paddingOffset}px`;
      return `
            border-radius: ${mainValue};
          `;
    }
    default: {
      const mainValue = 8 - $paddingOffset;
      if (!$squareBottom) return `border-radius: ${mainValue}px;`;
      const topLeft = mainValue;
      const topRight = mainValue;
      const bottomLeft = $squareBottom ? 0 : mainValue;
      const bottomRight = $squareBottom ? 0 : mainValue;
      return `
            border-radius: ${topLeft}px ${topRight}px ${bottomLeft}px ${bottomRight}px;
          `;
    }
  }
};

export default borderRadius;
