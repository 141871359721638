import { colors, virtues } from "./constants";

const theme = {
  colors: {
    textLight: "#eceff0",
    textPrimary: "#15436a",
    textSecondary: "#586b74",
    textSecondaryDark: "#4d5f68",
    textBlack: "#383838",
    textWarning: "#d5a223",
    divider: "#98A4A5",
    black: "#1E292E",
    white: "#ffffff",
    offWhite: "#eceff0",
    fnWhite: "#d4e1e3",
    ghostWhite: "#ffffff33",
    fnDarkGreen: "#0f8843",
    fnLightBlue: "#daeaf5",
    fnBlue: "#278dc1",
    fnDarkBlue: "#016099",
    fnDarkerBlue: "#13426B",
    purple: "#8B169C",
    error: "#B4252D",
    fnRed: "#f42434",
    fnGrey: "#e0e0e0",
    fnGray: "#e0e0e0",
    fnBlueGrey: "#edeff0",
    fnDarkBlueGrey: "#394a59",
    fnDarkestBlueGrey: "#2f3f4f" /* 😅 */,
    fnMediumGrey: "#d4d9db",
    fnDarkGrey: "#bec3c7",
    fnYellowNotice: "#f7f0b6",
    lightGray: "#f1f4f6",
    transparent: "#ffffff00",
  },
  // palette is reserved for colors from the marketing style guide. Don't make stuff up in this section unless you found it here: https://www.canva.com/design/DAFocu_KDts/L6lqfxVfW-cugks03mJq0Q/view?amp&amp&amp&utm_source=integration_slack&utm_medium=referral&utm_content=T064BMLMN&utm_campaign=unfurl_document-url_open-in-canva_hyperlink&utm_term=005c2fea-109d-4ea6-9477-260694a0229b#9
  palette: {
    text: {
      textPrimary: colors.fnBlue,
      textWarning: "#d5a223", // TODO: replace this with something from the official color palette when it is finalized
      primary: colors.fnBlue,
      textSecondary: colors.taupe,
      secondary: colors.taupe,
      taupe: colors.taupe,
      textBlack: colors.darkBlue,
      default: colors.darkBlue,
      blue: colors.blue,
      textLight: colors.grey,
      grey: colors.grey,
      legacyFnBlue: "#15436a",
    },
    icon: {
      [virtues.veracity]: colors.green,
      [virtues.hope]: colors.yellow,
      [virtues.wisdom]: colors.red,
      [virtues.justice]: colors.red,
      [virtues.humility]: colors.green,
      [virtues.courage]: colors.sky,
      [virtues.temperance]: colors.blue,
      [virtues.patience]: colors.fnBlue,
      [virtues.love]: colors.red,
      [virtues.faith]: colors.yellow,
    },
    // example for getting a color from the theme without these aliases:
    // ({ theme: { palette: { fnBlue } } }) => fnBlue
    ...colors, // this allows us to just get at the colors by their names when you need them.
  },
  fontSizes: {
    xs: ".6875em",
    sm: ".875em",
    md: "1em",
    lg: "1.125em",
    xl: "1.5em",
    xxl: "1.625em",
    // decorative sizes are their own thing. Alignments are based on element edges instead of line-height.
    decorative1: "1.25em",
    decorative2: "2em",
    decorative3: "3em",
  },
  shadows: {
    default: "box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);",
    selectList: "box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.25);",
  },
};

export default theme;
