import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const Heart = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 35.28">
    <FnrPath
      $virtue="love"
      d="M33.97,10.34c-1.81-9.28-13.13-9.44-15.93-.76C15.25.9,3.92,1.06,2.11,10.34c-1.64,8.42,15.93,21.71,15.93,21.71,0,0,17.57-13.29,15.93-21.71Z"
    />
  </FnrSvg>
);

Heart.propTypes = { size: number };

export default Heart;
