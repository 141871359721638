import Modal from "./Modal/Modal";

const GlobalComponents = () => (
  <>
    {/* Add any components here that should always be rendered. */}
    <Modal />
  </>
);

export default GlobalComponents;
