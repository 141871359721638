import { shadowVariants } from "theme/constants";

const shadow = ({ $variant, $shadowVariant, $noShadow }) => {
  if ($noShadow) return "";
  switch ($shadowVariant) {
    case shadowVariants.insetDark:
      return "box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 5px;";
    default: // continue to standard variants
  }

  switch ($variant) {
    case "button":
      return "filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));";
    case "selectList":
      return "box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.25);";
    default:
      return "box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);";
  }
};

export default shadow;
